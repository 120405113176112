import React from "react";

function TransparentAndReasonable() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            fill="none"
            viewBox="0 0 56 56"
        >
            <circle cx="27.859" cy="27.859" r="27.859" fill="#12366D"></circle>
            <path
                fill="#fff"
                d="M19.692 13.956h1.116V13h-2.072v2.071h.956v-1.115zM38.456 13h-2.941v.956h2.941V13zM25.218 13h-2.941v.956h2.941V13zM29.632 13H26.69v.956h2.942V13zM34.046 13h-2.942v.956h2.942V13zM39.928 13v.956h1.116v1.115H42V13h-2.072zM42 25.368h-.956v2.942H42v-2.942zM42 20.954h-.956v2.942H42v-2.942zM42 29.782h-.956v2.941H42v-2.941zM42 16.544h-.956v2.941H42v-2.941zM41.044 35.308h-1.115v.956H42v-2.072h-.956v1.116zM25.218 35.308h-2.941v.956h2.941v-.956zM34.046 35.308h-2.942v.956h2.942v-.956zM29.632 35.308H26.69v.956h2.942v-.956zM20.808 35.308h-1.116v-1.116h-.956v2.072h2.072v-.956zM19.692 29.782h-.956v2.941h.956v-2.941zM19.692 20.954h-.956v2.942h.956v-2.942zM19.692 25.368h-.956v2.942h.956v-2.942z"
            ></path>
            <path
                fill="#fff"
                d="M36.264 18.736H19.692v-2.192h-.956v2.192H13V42h23.264v-5.736h2.192v-.956h-2.192V18.736zm-.956 22.308H13.956V19.692h21.352v21.352z"
            ></path>
        </svg>
    );
}

export default TransparentAndReasonable;
