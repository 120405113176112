import React from "react";

function AccreditationFromIndustry() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            fill="none"
            viewBox="0 0 56 56"
        >
            <circle cx="27.859" cy="27.859" r="27.859" fill="#12366D"></circle>
            <path
                fill="#fff"
                d="M15.918 39.63a.383.383 0 01-.371-.476l1.539-6.047c-1.287-.868-2.308-1.875-3.037-2.995-.81-1.245-1.22-2.572-1.22-3.944 0-2.663 1.553-5.154 4.372-7.017 2.771-1.83 6.448-2.837 10.353-2.837 3.905 0 7.582 1.008 10.353 2.837 2.82 1.863 4.372 4.355 4.372 7.017 0 2.663-1.553 5.155-4.372 7.017-2.771 1.83-6.448 2.837-10.353 2.837-1.984 0-3.906-.257-5.717-.767l-5.688 4.298a.385.385 0 01-.231.078zm11.636-22.55c-7.697 0-13.96 4.077-13.96 9.088 0 2.434 1.47 4.724 4.138 6.448a.382.382 0 01.162.416l-1.336 5.253 4.97-3.756a.385.385 0 01.34-.062c1.795.524 3.708.79 5.687.79 7.697 0 13.96-4.077 13.96-9.089 0-5.011-6.263-9.089-13.96-9.089z"
            ></path>
            <path
                fill="#fff"
                d="M21.778 28.749a.384.384 0 01-.178-.044l-1.666-.876-1.665.876a.382.382 0 01-.555-.404l.318-1.854-1.348-1.314a.383.383 0 01.212-.653l1.863-.27.832-1.687a.383.383 0 01.687 0l.833 1.687 1.862.27a.383.383 0 01.212.653l-1.348 1.314.318 1.854a.383.383 0 01-.377.448zm-1.844-1.735c.062 0 .123.015.178.044l1.158.608-.221-1.288a.384.384 0 01.11-.34l.936-.912-1.294-.188a.383.383 0 01-.288-.21l-.579-1.172-.578 1.173a.383.383 0 01-.288.21l-1.294.187.936.913c.09.088.131.215.11.339l-.221 1.288 1.157-.608a.382.382 0 01.178-.044zM29.495 28.749a.384.384 0 01-.178-.044l-1.665-.876-1.666.876a.382.382 0 01-.555-.404l.318-1.854-1.347-1.314a.382.382 0 01.212-.653l1.862-.27.833-1.687a.383.383 0 01.686 0l.833 1.687 1.861.27a.383.383 0 01.212.653l-1.347 1.314.318 1.854a.382.382 0 01-.377.448zm-1.843-1.735c.061 0 .122.015.178.044l1.157.608-.221-1.288a.384.384 0 01.11-.34l.936-.912-1.293-.188a.382.382 0 01-.288-.21l-.579-1.172-.58 1.173a.38.38 0 01-.287.21l-1.294.188.937.912c.09.088.13.215.11.34l-.222 1.288 1.157-.609a.38.38 0 01.18-.044zM37.213 28.749a.386.386 0 01-.178-.044l-1.666-.876-1.665.876a.382.382 0 01-.555-.404l.318-1.854-1.347-1.314a.384.384 0 01.211-.653l1.862-.27.833-1.687a.383.383 0 01.687 0l.833 1.687 1.862.27a.383.383 0 01.211.653l-1.348 1.314.319 1.854a.384.384 0 01-.377.448zm-1.844-1.735a.38.38 0 01.178.044l1.157.608-.22-1.288a.383.383 0 01.11-.34l.935-.912-1.293-.188a.38.38 0 01-.288-.21l-.58-1.172-.578 1.173a.38.38 0 01-.288.21l-1.294.187.937.913c.09.088.13.215.11.339l-.221 1.288 1.157-.608a.384.384 0 01.178-.044z"
            ></path>
        </svg>
    );
}

export default AccreditationFromIndustry;
