import React from "react";

function PersonalizedSolutions() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            fill="none"
            viewBox="0 0 56 56"
        >
            <circle cx="27.859" cy="27.859" r="27.859" fill="#12366D"></circle>
            <path
                fill="#fff"
                d="M18.392 23.754v8.557c0 .866.344 1.696.954 2.308.61.612 1.438.956 2.3.957h3.637L22.49 38.38a.652.652 0 00-.008.932.65.65 0 00.928-.008l3.906-3.919a.653.653 0 000-.923l-3.906-3.92a.65.65 0 00-1.103.465.654.654 0 00.183.46l2.794 2.803h-3.636a1.952 1.952 0 01-1.38-.574 1.965 1.965 0 01-.573-1.385v-8.557a4.554 4.554 0 002.898-1.658 4.582 4.582 0 00-.444-6.213 4.549 4.549 0 00-6.21 0 4.578 4.578 0 00-.445 6.213 4.554 4.554 0 002.899 1.658zm.65-7.772A3.248 3.248 0 0122.05 18a3.275 3.275 0 01-.705 3.558 3.252 3.252 0 01-3.548.708 3.258 3.258 0 01-1.46-1.203 3.274 3.274 0 01.405-4.122 3.254 3.254 0 012.3-.958zm18.88 14.435v-8.556a3.274 3.274 0 00-.954-2.308 3.254 3.254 0 00-2.3-.958H31.03l2.795-2.804a.654.654 0 00-.463-1.107.65.65 0 00-.458.184l-3.906 3.918a.652.652 0 000 .924l3.906 3.919a.65.65 0 001.103-.464.654.654 0 00-.182-.46l-2.795-2.804h3.636c.518 0 1.015.207 1.38.575.367.367.573.865.573 1.385v8.556a4.553 4.553 0 00-2.898 1.658 4.582 4.582 0 00.445 6.214 4.549 4.549 0 006.21 0 4.578 4.578 0 00.444-6.214 4.554 4.554 0 00-2.899-1.658zm-.65 7.772a3.248 3.248 0 01-3.008-2.016 3.275 3.275 0 01.706-3.559 3.252 3.252 0 013.547-.708 3.273 3.273 0 011.055 5.325 3.254 3.254 0 01-2.3.958z"
            ></path>
        </svg>
    );
}

export default PersonalizedSolutions;
