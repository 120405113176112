import React from "react";

function PersonalizedDebtReliefPlan() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="55"
            height="50"
            fill="none"
            viewBox="0 0 55 50"
        >
            <g fill="#B6E7FF" clipPath="url(#clip0_1_141)">
                <path d="M.796 50.001a.793.793 0 01-.563-.236.81.81 0 01-.233-.569V10.37a.804.804 0 01.23-.568l4.707-4.807L9.697.23a.79.79 0 01.563-.233h28.156c.21 0 .413.085.562.236a.81.81 0 01.234.569v7.246l.32.154a12.3 12.3 0 014.884 4.242 12.56 12.56 0 012.02 8.559 12.498 12.498 0 01-4.08 7.773l-.375.331 3.075 4.579.313-.014h.107c.407 0 .809.1 1.169.293.36.192.668.471.897.812l6.249 9.306a2.515 2.515 0 01.386 1.893 2.517 2.517 0 01-1.05 1.61 2.459 2.459 0 01-1.397.435c-.407 0-.808-.101-1.167-.294a2.502 2.502 0 01-.896-.813l-6.25-9.306a2.523 2.523 0 01-.21-2.448l.127-.284-3.075-4.58-.442.228c-.094.05-.188.095-.288.145l-.32.154v18.372a.81.81 0 01-.233.57.793.793 0 01-.562.235H.796zm.796-1.61h36.026V31.419l-.685.155c-.882.2-1.783.3-2.687.3a12.216 12.216 0 01-5.776-1.442 12.363 12.363 0 01-4.433-4.01 12.427 12.427 0 01-1.872-4.469c-.325-1.6-.334-3.249-.025-4.852a12.43 12.43 0 011.798-4.503 12.295 12.295 0 013.375-3.455 12.117 12.117 0 019.228-1.92c.116.023.24.05.391.083l.686.155V1.613H10.584v8.708c0 .1-.039.197-.11.268a.373.373 0 01-.264.11H1.593v37.693zM34.2 8.982a10.185 10.185 0 00-5.782 1.798 10.327 10.327 0 00-2.836 2.902 10.442 10.442 0 00-1.51 3.783 10.492 10.492 0 00.019 4.08 10.444 10.444 0 005.296 7.125 10.26 10.26 0 004.851 1.213 10.202 10.202 0 005.795-1.799 10.473 10.473 0 004.347-6.686 10.555 10.555 0 00-1.596-7.835 10.328 10.328 0 00-2.873-2.866 10.216 10.216 0 00-3.743-1.527 10.423 10.423 0 00-1.968-.188zM6.067 6.129L2.336 9.94h7.5V2.36L6.066 6.129z"></path>
                <path d="M16.57 17.017H6.91v.757h9.66v-.758zM16.57 23.173H6.91v.757h9.66v-.757zM16.57 29.328H6.91v.757h9.66v-.757zM32.449 35.484H6.91v.757H32.45v-.757zM32.449 41.64H6.91v.757H32.45v-.758z"></path>
            </g>
            <defs>
                <clipPath id="clip0_1_141">
                    <path fill="#fff" d="M0 0H54.225V50H0z"></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default PersonalizedDebtReliefPlan;
