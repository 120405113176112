import React, { ReactElement } from "react";
import Image from "next/image";
import SharedButton from "../sharedButton";
export default function WhySeekDebtRelief(): ReactElement {
    return (
        <section className="flex flex-col lg:flex-row  lg:gap-40 gap-8 lg:mt-28 mt-14 ">
            <div className="max-w-[550px] mx-auto lg:mx-0 order-2 ">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-[45px] text-[#253F91] ">
                    Why Should You Seek Debt Relief?
                </h2>

                <p className="mt-7 font-light lg:text-lg  text-base lg:leading-8 ">
                    Debt relief companies are experts in helping people who feel
                    overwhelmed by unmanageable debt. Their main aim is to
                    provide practical solutions to ease financial burdens. They
                    typically offer services like negotiating with creditors to
                    reduce the total amount owed, consolidating debts into a
                    single manageable payment, providing credit counseling to
                    educate clients about managing money, and assisting with
                    bankruptcy proceedings if needed.
                </p>
                <p className="mt-4 font-light lg:text-lg  text-base lg:leading-8 ">
                    These companies work closely with clients to create
                    personalized plans for repaying debt, negotiate lower
                    interest rates, and explore legal options for relief. The
                    ultimate goal is to help individuals regain control of their
                    finances and move towards a debt-free future.
                </p>
                <div className="sm:mt-6 mt-3 text-center sm:text-left">
                    <SharedButton />
                </div>
            </div>
            <div className="w-[221px] h-[253px] md:w-[500px] md:min-w-[500px] md:h-[600px]  relative  mx-auto lg:mx-0 order-1  ">
                <Image
                    src={"/nomoredebttoday/assets/images/whySeekImg.png"}
                    alt="Image"
                    layout="fill"
                />
            </div>
        </section>
    );
}
