import React from "react";

function ComprehensiveFinancialAssessment() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="51"
            height="50"
            fill="none"
            viewBox="0 0 51 50"
        >
            <path
                fill="#B6E7FF"
                d="M21.703 33.146a4.904 4.904 0 01-2.72-.823 4.871 4.871 0 01-.742-7.512 4.899 4.899 0 015.335-1.058c.894.37 1.659.995 2.197 1.798.537.803.825 1.747.825 2.713a4.881 4.881 0 01-1.436 3.45 4.907 4.907 0 01-3.459 1.432zm0-7.841a2.973 2.973 0 00-2.741 1.826 2.953 2.953 0 00.643 3.226 2.969 2.969 0 003.233.64 2.966 2.966 0 001.831-2.733c0-.785-.313-1.537-.87-2.091a2.974 2.974 0 00-2.096-.868zM42.285 13.963a.973.973 0 01-.216-.023l-4.296-.981a.965.965 0 01-.723-.721l-.988-4.288a.96.96 0 01.258-.895L43.115.281a.965.965 0 011.645.651l.128 4.207 4.218.128a.966.966 0 01.87.607.958.958 0 01-.218 1.037l-6.791 6.771a.965.965 0 01-.682.281zm-3.492-2.745l3.187.728 4.837-4.825-2.892-.087a.965.965 0 01-.934-.932l-.088-2.885-4.837 4.825.727 3.176z"
            ></path>
            <path
                fill="#B6E7FF"
                d="M21.703 29.225a.966.966 0 01-.89-.593.96.96 0 01.208-1.048l16.285-16.242A.963.963 0 1138.67 12.7L22.385 28.944a.966.966 0 01-.682.281z"
            ></path>
            <path
                fill="#B6E7FF"
                d="M21.703 50a21.76 21.76 0 01-14.569-5.617A21.632 21.632 0 014.252 15.45a21.735 21.735 0 0113.176-8.366 21.777 21.777 0 0115.399 2.611 21.682 21.682 0 019.664 12.239 21.62 21.62 0 01-1.063 15.543A21.704 21.704 0 0130.186 48.29 21.7 21.7 0 0121.703 50zm0-41.55c-10.953 0-19.865 8.89-19.865 19.814s8.912 19.813 19.865 19.813c10.954 0 19.864-8.889 19.864-19.813 0-10.925-8.911-19.813-19.864-19.813z"
            ></path>
            <path
                fill="#B6E7FF"
                d="M21.703 44.726a16.53 16.53 0 01-10.47-3.736 16.454 16.454 0 01-5.718-9.513 16.424 16.424 0 011.631-10.971 16.49 16.49 0 018.24-7.45 16.545 16.545 0 0111.106-.545 16.502 16.502 0 018.933 6.607 16.43 16.43 0 01-2.05 20.786 16.436 16.436 0 01-11.671 4.823zm0-31.002c-8.038 0-14.578 6.523-14.578 14.54 0 8.016 6.54 14.54 14.578 14.54S36.28 36.28 36.28 28.263c0-8.018-6.54-14.54-14.577-14.54z"
            ></path>
            <path
                fill="#B6E7FF"
                d="M21.703 39.453c-6.187 0-11.218-5.018-11.218-11.19 0-6.17 5.03-11.188 11.218-11.188s11.218 5.019 11.218 11.189-5.032 11.189-11.218 11.189zm0-20.455c-5.121 0-9.29 4.157-9.29 9.265 0 5.11 4.167 9.267 9.29 9.267 5.123 0 9.29-4.157 9.29-9.267 0-5.109-4.168-9.265-9.29-9.265z"
            ></path>
        </svg>
    );
}

export default ComprehensiveFinancialAssessment;
