import React, { ReactElement } from "react";

import SeveralKeyFactors from "./icons/severalKeyFactors";
import AccreditationFromIndustry from "./icons/accreditationFromIndustry";
import TransparentAndReasonable from "./icons/transparentAndReasonable";
import PersonalizedSolutions from "./icons/personalizedSolutions";
import PrivacyAndDataSecurity from "./icons/privacyAndDataSecurity";
import Image from "next/image";
const UnlockAdvantagesList = [
    {
        icon: <AccreditationFromIndustry />,
        description:
            "Accreditation from industry organizations such as the American Fair Credit Council (AFCC) or the International Association of Professional Debt Arbitrators (IAPDA) strongly indicates credibility",
    },
    {
        icon: <TransparentAndReasonable />,
        description:
            "Transparent and reasonable fees and a clear explanation of the cost structure are crucial to avoiding unexpected expenses",
    },
    {
        icon: <PersonalizedSolutions />,
        description:
            "A good debt relief company should offer personalized solutions tailored to your unique financial situation and needs",
    },
    {
        icon: <PrivacyAndDataSecurity />,
        description:
            "They should prioritize your privacy and data security and provide excellent customer support throughout the debt relief process",
    },
    {
        icon: <PrivacyAndDataSecurity />,
        description:
            "They should prioritize your privacy and data security and provide excellent customer support throughout the debt relief process",
    },
    {
        icon: <SeveralKeyFactors />,
        description:
            "Reading client reviews and testimonials can also offer valuable insights into the company's reputation and customer satisfaction.",
    },
];
export default function GoodDebtReliefCompany(): ReactElement {
    return (
        <div className="flex flex-col lg:flex-row lg:gap-20 gap-12   lg:mt-24 mt-12 lg:mb-48 mb-24">
            <div className="max-w-[700px] mx-auto lg:mx-0 ">
                <h2 className="font-bold lg:text-3xl text-2xl lg:leading-10 text-[#253F91] text-center sm:text-left">
                    What Should You Seek in a Good Debt Relief Company?
                </h2>
                <p className="lg:text-xl lg:leading-8  lg:mt-12 mt-16 text-center sm:text-left">
                    Several key factors should be on your checklist when
                    searching for a reputable debt relief company. These
                    include:
                </p>
                {UnlockAdvantagesList.map((advantage, index) => (
                    <div
                        key={index}
                        className="mt-10  first-of-type:mt-8 flex flex-col sm:flex-row lg:gap-7  gap-5  items-center "
                    >
                        <span>{advantage.icon}</span>
                        <p className="lg:text-xl lg:leading-8  text-center sm:text-left">
                            {advantage.description}
                        </p>
                    </div>
                ))}
            </div>
            <div className=" w-[487px] min-w-[487px] h-[625px]  relative   mt-24 hidden lg:block ">
                <Image
                    src={"/nomoredebttoday/assets/images/goodDebtReliefImg.jpg"}
                    alt="Image"
                    layout="fill"
                />
            </div>
        </div>
    );
}
