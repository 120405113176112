import React, { ReactElement } from "react";
import styles from "./style.module.scss";
import FeatureList from "@components/shared/featureList";
import SharedButton from "../sharedButton";
import ComprehensiveFinancialAssessment from "./icons/comprehensiveFinancialAssessment";
import PersonalizedDebtReliefPlan from "./icons/personalizedDebtReliefPlan";
import CompanyCommunicates from "./icons/companyCommunicates";
import FundSettlements from "./icons/fundSettlements";
import ProcessUnfolds from "./icons/processUnfolds";
const DebtReliefProcessLikeList = [
    {
        description:
            "It begins with a comprehensive financial assessment, evaluating the client's debts, income, and financial goals",
        icon: <ComprehensiveFinancialAssessment />,
    },
    {
        description:
            "Based on this assessment, a personalized debt relief plan is created, which may include debt settlement negotiations, debt consolidation, credit counseling, or bankruptcy",
        icon: <PersonalizedDebtReliefPlan />,
    },
    {
        description:
            "Throughout the process, the debt relief company communicates with creditors to negotiate favorable terms, such as reduced balances or interest rates",
        icon: <CompanyCommunicates />,
    },
    {
        description:
            "Clients are often required to make regular payments into a dedicated account to fund settlements or consolidated payments",
        icon: <FundSettlements />,
    },
    {
        description:
            "As the process unfolds, clients can see their debt gradually decrease, ultimately leading to a more manageable financial situation and, in some cases, becoming debt-free",
        icon: <ProcessUnfolds />,
    },
];

export default function DebtReliefProcessLike(): ReactElement {
    return (
        <section className={styles["mainContainer"]}>
            <div className="lg:pt-[97px] lg:pb-[142px] pt-[92px] pb-[82px]">
                <FeatureList
                    featureList={DebtReliefProcessLikeList}
                    mainTitle="What Is the Debt Relief Process Like?"
                    mainDescription={
                        "The debt relief process typically involves several key steps aimed at helping individuals regain financial stability."
                    }
                    colors={{
                        mainTitleColor: "white",
                        descriptionColor: "white",
                        mainDescriptionColor: "white",
                    }}
                    classes={{
                        mainSectionClasses: "  ",
                        mainTitleClasses:
                            " text-2xl  lg:text-3xl font-bold text-center sm:text-left",
                        mainDescriptionClasses:
                            "mt-[20px] lg:text-xl lg:leading-8 text-center sm:text-left leading-7 font-semibold",
                        innerSectionClasses:
                            "flex   flex-wrap   sm:gap-[88px] gap-[60px] mt-[50px] ",
                        oneBoxClasses:
                            "  w-full max-w-[350px] mx-auto sm:mx-0 sm:w-[300px] ",
                        iconClasses: "children:mx-auto sm:children:mx-0 ",
                        descriptionClasses: `font-light text-center sm:text-left `,
                    }}
                />
                <p className="lg:text-xl lg:leading-8 text-center sm:text-left leading-7 font-semibold text-[#fff] lg:mt-[70px] mt-8">
                    Open communication with the debt relief company is essential
                    to ensuring a successful outcome.
                </p>
                <div className="mt-5  text-center sm:text-left">
                    <SharedButton />
                </div>
            </div>
        </section>
    );
}
