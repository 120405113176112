import React from "react";

function FundSettlements() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="64"
            height="50"
            fill="none"
            viewBox="0 0 64 50"
        >
            <path
                fill="#B6E7FF"
                d="M28.381 32.424c-1.094 0-1.983-.752-1.983-1.71 0-.957.89-1.71 1.983-1.71 1.095 0 1.984.753 1.984 1.71 0 .547.479 1.026 1.026 1.026s1.026-.479 1.026-1.026c0-1.778-1.3-3.214-3.01-3.624V25.31c0-.547-.478-1.025-1.026-1.025-.547 0-1.025.478-1.025 1.025v1.779c-1.71.41-3.01 1.915-3.01 3.624 0 2.12 1.779 3.762 4.035 3.762 1.095 0 1.984.752 1.984 1.71 0 .957-.89 1.71-1.983 1.71-1.095 0-1.984-.753-1.984-1.71 0-.548-.479-1.026-1.026-1.026s-1.025.478-1.025 1.025c0 1.779 1.299 3.215 3.009 3.625v1.778c0 .547.478 1.026 1.025 1.026.548 0 1.026-.479 1.026-1.026V39.81c1.71-.41 3.01-1.915 3.01-3.625 0-2.051-1.847-3.761-4.036-3.761z"
            ></path>
            <path
                fill="#B6E7FF"
                d="M55.737 50H1.026A1.052 1.052 0 010 48.974V17.857c0-.547.479-1.026 1.026-1.026h54.711c.547 0 1.026.479 1.026 1.026v31.117c0 .616-.479 1.026-1.026 1.026zM2.052 47.948h52.66V18.883H2.051v29.065z"
            ></path>
            <path
                fill="#B6E7FF"
                d="M47.12 45.76h-6.428a1.052 1.052 0 01-1.026-1.026c0-.547.479-1.026 1.026-1.026h6.018l3.898-3.35V26.542L46.71 23.19h-6.018a1.052 1.052 0 01-1.026-1.025c0-.547.479-1.026 1.026-1.026h6.428c.274 0 .479.068.684.273l4.514 3.898c.205.206.342.48.342.753v14.772c0 .273-.137.547-.342.752l-4.514 3.898c-.205.206-.41.274-.684.274zM16.072 45.76h-6.43c-.273 0-.478-.069-.683-.274l-4.514-3.898c-.205-.205-.342-.479-.342-.752V26.064c0-.274.137-.616.342-.753l4.514-3.898c.205-.137.41-.273.684-.273h6.428c.548 0 1.026.479 1.026 1.026s-.478 1.025-1.026 1.025h-6.018l-3.898 3.352v13.814l3.898 3.351h6.018c.548 0 1.026.479 1.026 1.026s-.478 1.026-1.026 1.026z"
            ></path>
            <path
                fill="#B6E7FF"
                d="M28.381 45.76c-6.77 0-12.31-5.54-12.31-12.31 0-6.77 5.54-12.31 12.31-12.31 6.771 0 12.31 5.54 12.31 12.31 0 6.77-5.539 12.31-12.31 12.31zm0-22.569A10.245 10.245 0 0018.123 33.45a10.245 10.245 0 0010.258 10.258A10.245 10.245 0 0038.64 33.45 10.245 10.245 0 0028.38 23.19zM29.065 16.147h2.941c.137-.068.274-.068.41-.068.343-.069.684 0 1.026.068h2.873c-.547-1.162-1.778-1.983-3.146-2.12l-.274-1.71c-.068-.547-.615-.957-1.162-.889-.547.069-.958.616-.89 1.163l.274 1.71c-.889.41-1.573 1.094-2.052 1.846z"
            ></path>
            <path
                fill="#B6E7FF"
                d="M63.465 31.672L58.883.897c-.068-.548-.615-.958-1.163-.89L3.556 8.146c-.547.068-.957.615-.82 1.162l.957 6.84h2.052l-.89-6.156 52.113-7.796 4.309 28.723-3.83.547v2.052l5.13-.752c.546 0 .957-.479.888-1.094z"
            ></path>
            <path
                fill="#B6E7FF"
                d="M43.77 8.556l5.949-.889 4.309 2.736.889 5.744h.82c.547 0 1.026.274 1.3.616l-1.095-7.181c-.068-.274-.205-.547-.478-.684l-5.061-3.214c-.205-.137-.479-.205-.684-.137l-6.36.958c-.547.068-.958.615-.89 1.162.206.616.685.958 1.3.89zM23.8 16.147c1.436-3.009 4.24-5.266 7.727-5.813 4.582-.684 8.891 1.778 10.806 5.813h2.257c-1.984-5.266-7.523-8.685-13.336-7.796-4.582.684-8.138 3.761-9.711 7.796h2.256zM10.737 16.147l2.667-3.077 5.95-.89c.547-.068.958-.615.89-1.162-.07-.547-.616-.957-1.163-.889l-6.36.957c-.274.069-.48.137-.616.342l-3.898 4.514c-.069.069-.069.137-.137.205h2.667z"
            ></path>
        </svg>
    );
}

export default FundSettlements;
