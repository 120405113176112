import { ReactElement } from "react";
import styles from "./style.module.scss";

export default function SharedButton(): ReactElement {
    const scrollToTop = () => {
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    return (
        <button
            onClick={() => scrollToTop()}
            className={`bg-[#0288cc] color-[#ffffff]  py-4 sm:px-16 px-4 sm:text-lg font-semibold rounded-[13px] ${styles["animation"]} `}
        >
            Get My Free Debt Relief Consultation Now
        </button>
    );
}
