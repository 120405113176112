import React, { ReactElement } from "react";
import styles from "./style.module.scss";
import SharedButton from "../sharedButton";
export default function DebtReliefCompanyBenefit(): ReactElement {
    return (
        <section
            className={` lg:mt-28 mt-16 md:p-20 py-10 px-5 ${styles["mainSection"]} lg:mb-[200px] mb-[100px]`}
        >
            <div className="  mx-auto  text-[#fff]  sm:text-center">
                <h2 className="font-bold lg:text-3xl text-xl lg:leading-[45px]  ">
                    How Can a Debt Relief Company Benefit You?
                </h2>
                <p className="mt-7 font-light lg:text-lg  text-base lg:leading-8 ">
                    A debt relief company can offer some major benefits to folks
                    dealing with overwhelming debt. These companies specialize
                    in negotiating with creditors for reduced debt amounts,
                    lower interest rates, or more manageable repayment terms.
                    With their help, you can find relief from the stress and
                    burden of unmanageable debt.
                </p>
                <div className="lg:mt-10 mt-5">
                    <SharedButton />
                </div>
            </div>
        </section>
    );
}
