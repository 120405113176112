import React from "react";

function CompanyCommunicates() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="51"
            height="50"
            fill="none"
            viewBox="0 0 51 50"
        >
            <path
                fill="#B6E7FF"
                d="M49.238 20h-4.803a19.799 19.799 0 00-2.137-5.156l3.397-3.397a.834.834 0 000-1.179l-5.892-5.892a.833.833 0 00-1.179 0l-3.397 3.397a19.824 19.824 0 00-5.156-2.137V.833A.834.834 0 0029.238 0h-8.334a.833.833 0 00-.833.833v4.803a19.824 19.824 0 00-5.156 2.137l-3.397-3.397a.833.833 0 00-1.179 0l-5.892 5.892a.833.833 0 000 1.179l3.397 3.397A19.799 19.799 0 005.707 20H.904a.833.833 0 00-.833.833v8.334A.833.833 0 00.904 30h4.803a19.799 19.799 0 002.137 5.156l-3.397 3.397a.833.833 0 000 1.179l5.892 5.892a.833.833 0 001.179 0l3.397-3.397a19.827 19.827 0 005.156 2.137v4.803a.833.833 0 00.833.833h8.334a.833.833 0 00.833-.833v-4.803a19.826 19.826 0 005.156-2.137l3.397 3.397a.833.833 0 001.179 0l5.892-5.892a.834.834 0 000-1.179l-3.397-3.397A19.799 19.799 0 0044.435 30h4.803a.833.833 0 00.833-.833v-8.334a.834.834 0 00-.833-.833zm-.834 8.333h-4.626a.833.833 0 00-.814.654 18.165 18.165 0 01-2.424 5.846.833.833 0 00.114 1.037l3.274 3.273-4.714 4.714-3.274-3.274a.833.833 0 00-1.036-.113 18.203 18.203 0 01-5.847 2.424.833.833 0 00-.653.814v4.625h-6.666v-4.625a.833.833 0 00-.653-.814 18.203 18.203 0 01-5.847-2.424.833.833 0 00-1.037.113l-3.273 3.273-4.714-4.714 3.274-3.273a.834.834 0 00.114-1.036 18.167 18.167 0 01-2.424-5.846.833.833 0 00-.814-.654H1.738v-6.666h4.626a.833.833 0 00.814-.654 18.166 18.166 0 012.424-5.846.833.833 0 00-.114-1.037l-3.274-3.273 4.714-4.713 3.274 3.273a.833.833 0 001.037.113 18.204 18.204 0 015.846-2.424.833.833 0 00.653-.813V1.667h6.666v4.626a.833.833 0 00.653.813c2.076.46 4.055 1.28 5.847 2.424a.833.833 0 001.037-.113l3.273-3.273 4.715 4.713-3.275 3.274a.833.833 0 00-.114 1.037 18.166 18.166 0 012.424 5.845.833.833 0 00.814.654h4.626v6.666z"
            ></path>
            <path
                fill="#B6E7FF"
                d="M25.071 10a15 15 0 1015 15 15.018 15.018 0 00-15-15zm0 28.333A13.334 13.334 0 1138.404 25a13.35 13.35 0 01-13.333 13.333z"
            ></path>
            <path
                fill="#B6E7FF"
                d="M30.578 20.316l-7.599 7.6-3.416-3.416a.835.835 0 00-1.433.586.831.831 0 00.254.592l4.005 4.007a.834.834 0 001.179 0l8.188-8.19a.834.834 0 10-1.178-1.178v-.001z"
            ></path>
        </svg>
    );
}

export default CompanyCommunicates;
