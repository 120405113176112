import React from "react";

function PrivacyAndDataSecurity() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="56"
            height="56"
            fill="none"
            viewBox="0 0 56 56"
        >
            <circle cx="27.859" cy="27.859" r="27.859" fill="#12366D"></circle>
            <path
                fill="#fff"
                d="M40.201 18.126a.605.605 0 00-.465-.487c-.08-.018-8.144-1.818-11.786-3.579a.604.604 0 00-.526 0c-3.641 1.761-11.705 3.562-11.787 3.58a.605.605 0 00-.464.486c-.008.044-.685 4.796.696 10.439 1.678 6.856 5.681 11.83 11.577 14.386a.614.614 0 00.48 0c5.897-2.556 9.9-7.53 11.578-14.387 1.382-5.642.705-10.394.697-10.438zm-1.87 10.151c-1.585 6.474-5.166 11-10.644 13.459-5.479-2.459-9.06-6.986-10.644-13.459-1.082-4.422-.84-8.335-.723-9.553 1.695-.39 7.973-1.89 11.367-3.452 3.393 1.562 9.671 3.061 11.367 3.451.117 1.22.36 5.132-.724 9.554z"
            ></path>
            <path
                fill="#fff"
                d="M36.89 20.069a111.46 111.46 0 01-3.6-.954.604.604 0 10-.33 1.162c1.019.289 2.089.575 3.186.85a28.108 28.108 0 01-.164 3.078.605.605 0 001.202.13c.134-1.23.19-2.471.166-3.692a.604.604 0 00-.46-.574zM31.01 25.48h-6.646c-1 0-1.813.812-1.813 1.812v4.833c0 1 .814 1.812 1.813 1.812h6.646c1 0 1.812-.813 1.812-1.812v-4.833c0-1-.813-1.813-1.812-1.813zm.604 6.645c0 .333-.27.604-.604.604h-6.646a.604.604 0 01-.604-.604v-4.833c0-.334.27-.605.604-.605h6.646c.333 0 .604.271.604.605v4.833zM24.666 24.875c.334 0 .604-.27.604-.604a2.42 2.42 0 012.417-2.417 2.42 2.42 0 012.416 2.417.604.604 0 101.209 0c0-2-1.626-3.625-3.625-3.625-2 0-3.625 1.625-3.625 3.625 0 .334.27.604.604.604z"
            ></path>
        </svg>
    );
}

export default PrivacyAndDataSecurity;
